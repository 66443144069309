// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API_URL : 'http://localhost:8080/locatelliBE',
  API_URL : 'https://testapi-locatelliturni.soluzione1.it/locatelliBE',
  keycloak: {
    // Url of the Identity Provider
    issuer: 'https://testsso2.soluzione1.it/auth/',

    // Realm
    realm: 'LocatelliAutoserviziDev',

    // The SPA's id. 
    // The SPA is registerd with this id at the auth-serverß
    clientId: 'locatelli-fe',
  }
};


